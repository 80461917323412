import { csrfToken } from "@sonicgarden/rails-ujs-compat"

type Language = 'ja' | 'en'

type RequestParams = {
  text: string
  sourceLanguage: Language
}

const request = async (params: RequestParams): Promise<string> => {
  const response = await fetch('/members/translation', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken() ?? ''
    },
    body: JSON.stringify(params),
  })
  const json = await response.json()
  return json.translatedText as string
}

export class TranslateFields extends HTMLElement {
  connectedCallback(): void {
    this.addEventListener('click', this.onClick)
  }

  disconnectedCallback(): void {
    this.removeEventListener('click', this.onClick)
  }

  async translateBy(sourceLanguage: Language): Promise<void> {
    const targetLanguage: Language = sourceLanguage === 'ja' ? 'en' : 'ja'
    const sourceTextarea = this.getTextarea(sourceLanguage)
    if (!sourceTextarea) return
    const text = sourceTextarea.value
    if (text.length === 0) return

    const translatedText = await request({ text, sourceLanguage })
    const targetTextarea = this.getTextarea(targetLanguage)
    if (!targetTextarea) return
    targetTextarea.value = translatedText
  }

  private getTextarea(language: Language): HTMLTextAreaElement | undefined {
    return this.querySelector<HTMLTextAreaElement>(`[data-translate-input="${language}"]`) ?? undefined
  }

  onClick = async ({ target }: Event): Promise<void> => {
    if (!(target instanceof HTMLElement)) return

    const button = target.closest<HTMLButtonElement>('[data-translate-source]')
    const sourceLanguage = button?.dataset.translateSource
    if (!sourceLanguage) return

    button.disabled = true
    await this.translateBy(sourceLanguage as Language)
    button.disabled = false
  }
}

declare global {
  interface Window {
    TranslateFields: typeof TranslateFields
  }
}

if (!window.customElements.get('translate-fields')) {
  window.TranslateFields = TranslateFields
  window.customElements.define('translate-fields', TranslateFields)
}
