export class RemovableElement extends HTMLElement {
  connectedCallback(): void {
    this.addEventListener('click', this.onClick)
  }

  disconnectedCallback(): void {
    this.removeEventListener('click', this.onClick)
  }

  onClick = ({ target }: Event): void => {
    if (target instanceof HTMLElement && target.closest('[data-remove]')) {
      this.remove()
    }
  }
}

declare global {
  interface Window {
    RemovableElement: typeof RemovableElement
  }
}

if (!window.customElements.get('removable-element')) {
  window.RemovableElement = RemovableElement
  window.customElements.define('removable-element', RemovableElement)
}
