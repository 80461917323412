import { onConnect } from '@sonicgarden/onconnect'

const handler = (event: MouseEvent) => {
  window.print()
}

export const initPrint = () => {
  onConnect('[data-print]', (el) => {
    el.addEventListener('click', handler)

    // optional
    return () => {
      el.removeEventListener('click', handler)
    }
  })
}
