import { onConnect } from '@sonicgarden/onconnect'

const handler = (event: MouseEvent) => {
  const el = event.currentTarget
  if (!(el instanceof HTMLElement)) return

  const groupElement = el.closest<HTMLDivElement>('.tw-group')
  if (!groupElement) return

  groupElement.classList.toggle('is-open')
}

export const initMobileMenu = () => {
  onConnect('[data-mobile-menu]', (el) => {
    el.addEventListener('click', handler)

    // optional
    return () => {
      el.removeEventListener('click', handler)
    }
  })
}
